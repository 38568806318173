import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, of, from, switchMap, throwError } from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut} from "firebase/auth";
import { initializeApp } from "@firebase/app";
import { environment } from 'environments/environment';
import { AnyARecord } from 'dns';

@Injectable()
export class AuthService
{
    private _authenticated: boolean = false;
    private readonly app = initializeApp(environment.firebaseConfig);
    private auth = null;
    private userCredential = null;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
        
    )
    {
        this.auth = getAuth(this.app);
        onAuthStateChanged(this.auth, (user) => {
            if (user) {
              console.log("SAuth: user is loged in");
              this._userService.user = user;
              // ...
            } else {
                console.log("SAuth: user is NOT loged in");
              // User is signed out
              // ...
            }
          });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string)
    {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string
    {
        return localStorage.getItem('accessToken') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any>
    {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any>
    {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any>
    {
        // Throw error, if the user is already logged in
        if ( this._authenticated )
        {
            return throwError('User is already logged in.');
        }
        
        
        
        return this._httpClient.post('api/auth/sign-in', credentials).pipe(
            switchMap((response: any) => {

                // Store the access token in the local storage
                this.accessToken = response.accessToken;

                // Set the authenticated flag to true
                this._authenticated = true;
                console.log("SAuth: response sign-in:");
                console.log(response);
                // Store the user on the user service
                this._userService.user = response.user;

                // Return a new observable with the response
                return of(response);
            })
        );
        
    }

    signInFB(credentials: { email: string; password: string }): Promise<any>
    {
        // Throw error, if the user is already logged in
        if ( this._authenticated )
        {
            console.log('User is already logged in.');
            return 
            
        }
        
        
        
        return signInWithEmailAndPassword(this.auth, credentials.email, credentials.password)
            .then((userCredential: any) => {
            // Signed in 
                this.userCredential = userCredential.user;
                console.log("SAuthAPI: login auth confirmation.");
                console.log(userCredential);
                // Store the access token in the local storage
                this.accessToken = userCredential.user.accessToken;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service

                this._userService.user = userCredential.user;

                // Return a new observable with the response
                return of(userCredential);
                })
                .catch((error) => {
                    // Error
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    
                    console.log("SAuthAPI: error while login auth. Error code: " + errorCode + " Error message: " + errorMessage);
                    
                // ..
                });
                    //const access = await Promise.race([access1, access2]);
                
        
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any>
    {   

        // Sign in using the token
        return this._httpClient.post('api/auth/sign-in-with-token', {
            accessToken: this.accessToken
        }).pipe(
            catchError(() =>

                // Return false
                of(false)
            ),
            switchMap((response: any) => {

                // Replace the access token with the new one if it's available on
                // the response object.
                //
                // This is an added optional step for better security. Once you sign
                // in using the token, you should generate a new one on the server
                // side and attach it to the response object. Then the following
                // piece of code can replace the token with the refreshed one.
                console.log("SAuth: response:");
                console.log(response);
                if ( response.accessToken )
                {
                    this.accessToken = response.accessToken;
                }

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
               
                //    this._userService.user = response.user;
                

                // Return true
                return of(true);
            })
        );
    }
/*
    signInUsingTokenFB(): Promise<any>
    {   

        // Sign in using the token
        this.auth.verifyIdToken(token)
            .then((decodedToken) => {
                console.log("API: token verification:");
                console.log(decodedToken);
            })
            .catch((error) => {
                const errorCode = error.code;
                        const errorMessage = error.message;
                        
                        console.log("SAuthAPI: error while login auth. Error code: " + errorCode + " Error message: " + errorMessage);
                        
                    // ..
              });
        getAuth(this.app).currentUser.
        
        
        .then((decodedToken) => {
          const uid = decodedToken.uid;
          // ...
        })
        .catch((error) => {
          // Handle error
        });
    }*/
    /*
        //return this._httpClient.post('api/auth/sign-in-with-token', {
            accessToken: this.accessToken
        }).pipe(
            catchError(() =>

                // Return false
                of(false)
            ),
            switchMap((response: any) => {

                // Replace the access token with the new one if it's available on
                // the response object.
                //
                // This is an added optional step for better security. Once you sign
                // in using the token, you should generate a new one on the server
                // side and attach it to the response object. Then the following
                // piece of code can replace the token with the refreshed one.
                console.log("SAuth: response:");
                console.log(response);
                if ( response.accessToken )
                {
                    this.accessToken = response.accessToken;
                }

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
               
                //    this._userService.user = response.user;
                

                // Return true
                return of(true);
            })
        );
    }*/

    /**
     * Sign out
     */
    signOut(): Observable<any>
    {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');

        // Set the authenticated flag to false
        this._authenticated = false;
        this._userService.user = null;
        
        signOut(this.auth).then(() => {
        // Sign-out successful.
        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            
            console.log("SAuthAPI: error while signOut. Error code: " + errorCode + " Error message: " + errorMessage);
            });

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean>
    {   
        //console.log("check authenticated user data local: ");
        //console.log(this._userService.user);
        //console.log("check authenticated user data fb: ");
        //console.log(getAuth(this.app).currentUser);
        //getAuth(this.app).config.apiKey
        //console.log("check authenticated: " + this._authenticated );
        // Check if the user is logged in
        if ( this._authenticated )
        {
            return of(true);
        }
        //console.log("check accessToken: " + this.accessToken );
        // Check the access token availability
        if ( !this.accessToken )
        {
            return of(false);
        }
        //console.log("check isTokenExpired" + AuthUtils.isTokenExpired(this.accessToken) );
        // Check the access token expire date
        if ( AuthUtils.isTokenExpired(this.accessToken) )
        {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }
}
