import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, ReplaySubject, tap, switchMap, of, throwError  } from 'rxjs';
import { Navigation } from 'app/core/navigation/navigation.types';

@Injectable({
    providedIn: 'root'
})
export class NavigationService
{
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);
    private _apiVer: BehaviorSubject<any> = new BehaviorSubject(null);
    backendurl = environment.strApiUrl;

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
    }

    httpOptions = {
        headers: new HttpHeaders({
        'Content-Type': 'application/json',
        }),
    };
    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation>
    {
        return this._navigation.asObservable();
    }

    get apiVer$(): Observable<any>
    {
        return this._apiVer.asObservable();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation>
    {
        return this._httpClient.get<Navigation>('api/common/navigation').pipe(
            tap((navigation) => {
                this._navigation.next(navigation);
            })
        );
    }

    getApiVer(): Observable<any>
    {   
      
        //console.log("pytam o wersje api " + this.backendurl + '/getversion');
        return this._httpClient.get<any>(this.backendurl + '/getversion')
        .pipe(tap((ver) => {
                //console.log("b: api: " + ver);
                this._apiVer.next(ver);
                return ver;
            }),
          switchMap((data) => {
              
              if ( !data )
              {
                  return throwError('Could not found apiVer');
              }

              return of(data);
          })
        )
        
    }
    
}
