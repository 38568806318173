import { Injectable } from '@angular/core';
import { BehaviorSubject, from, filter, map, Observable, of, switchMap, take, tap, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { assign, cloneDeep } from 'lodash-es';
//import { FuseMockApiService, FuseMockApiUtils } from '@fuse/lib/mock-api';
import { Patent } from 'app/modules/admin/apps/patents/patents.types';
//import { patents as patentsData, countries as countriesData, tags as tagsData } from 'app/api/patents/data';

//const baseUrl: string =  'http://localhost:5321/app/api/patents/';
//const baseUrl: string =  '/app/api/patents/';

@Injectable({
    providedIn: 'root'
})
export class PatentsApi
{
    private _patents: any[];
    //backendurl = 'http://epoapi.now-dns.net:1338';
    //private _countries: any[] = countriesData;
    //private _tags: any[] = tagsData;

    /**
     * Constructor
     */
    constructor(
        //private _fuseMockApiService: FuseMockApiService,
        private _httpClient: HttpClient
        )
    {
        // Register Mock API handlers
      //  this.registerHandlers();

    }
    // -----------------------------------------------------------------------------------------------------
    // @ EPOAPI api methods 
    // -----------------------------------------------------------------------------------------------------
    /*getAll() {
        return this._httpClient
              .get(this.backendurl + '/patents/');
              //.pipe(retry(1), catchError(this.errorHandl));              
          }
     */   
    // Error handling
    errorHandl(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
        // Get client-side error
            errorMessage = error.error.message;
        } else {
        // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        //console.log(errorMessage);
        return throwError(() => {
            return errorMessage;
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void
    {
        // -----------------------------------------------------------------------------------------------------
        // @ Patents - GET
        // -----------------------------------------------------------------------------------------------------
       /* this._fuseMockApiService
            .onGet('api/patents/all')
            .reply(() => {

                // Clone the patents
                
                const patents = this.getAll();
               
                //const patents = cloneDeep(this._patents);

                // Sort the patents by the name field by default
                //patents.sort((a, b) => a.epid.localeCompare(b.epid));

                // Return the response
                return [200, patents];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Patents Search - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/patents/search')
            .reply(({request}) => {

                // Get the search query
                const query = request.params.get('query');

                // Clone the patents
                let patents = cloneDeep(this._patents);

                // If the query exists...
                if ( query )
                {
                    // Filter the patents
                    patents = patents.filter(patent => patent.name && patent.name.toLowerCase().includes(query.toLowerCase()));
                }

                // Sort the patents by the name field by default
                patents.sort((a, b) => a.name.localeCompare(b.name));

                // Return the response
                return [200, patents];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Patent - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/patents/patent')
            .reply(({request}) => {

                // Get the id from the params
                const id = request.params.get('id');

                // Clone the patents
                const patents = cloneDeep(this._patents);

                // Find the patent
                const patent = patents.find(item => item.id === id);

                // Return the response
                return [200, patent];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Patent - POST
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onPost('api/patents/patent')
            .reply(() => {

                // Generate a new patent
                const newPatent = {
                    id          : FuseMockApiUtils.guid(),
                    avatar      : null,
                    name        : 'New Patent',
                    emails      : [],
                    phoneNumbers: [],
                    job         : {
                        title  : '',
                        company: ''
                    },
                    birthday    : null,
                    address     : null,
                    notes       : null,
                    tags        : []
                };

                // Unshift the new patent
                this._patents.unshift(newPatent);

                // Return the response
                return [200, newPatent];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Patent - PATCH
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onPatch('api/patents/patent')
            .reply(({request}) => {

                // Get the id and patent
                const id = request.body.id;
                const patent = cloneDeep(request.body.patent);

                // Prepare the updated patent
                let updatedPatent = null;

                // Find the patent and update it
                this._patents.forEach((item, index, patents) => {

                    if ( item.id === id )
                    {
                        // Update the patent
                        patents[index] = assign({}, patents[index], patent);

                        // Store the updated patent
                        updatedPatent = patents[index];
                    }
                });

                // Return the response
                return [200, updatedPatent];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Patent - DELETE
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onDelete('api/patents/patent')
            .reply(({request}) => {

                // Get the id
                const id = request.params.get('id');

                // Find the patent and delete it
                this._patents.forEach((item, index) => {

                    if ( item.id === id )
                    {
                        this._patents.splice(index, 1);
                    }
                });

                // Return the response
                return [200, true];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Countries - GET
        // -----------------------------------------------------------------------------------------------------
        /*this._fuseMockApiService
            .onGet('api/patents/countries')
            .reply(() => [200, cloneDeep(this._countries)]);
    
        // -----------------------------------------------------------------------------------------------------
        // @ Tags - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/patents/tags')
            .reply(() => [200, cloneDeep(this._tags)]);

        // -----------------------------------------------------------------------------------------------------
        // @ Tags - POST
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onPost('api/patents/tag')
            .reply(({request}) => {

                // Get the tag
                const newTag = cloneDeep(request.body.tag);

                // Generate a new GUID
                newTag.id = FuseMockApiUtils.guid();

                // Unshift the new tag
                this._tags.unshift(newTag);

                // Return the response
                return [200, newTag];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Tags - PATCH
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onPatch('api/patents/tag')
            .reply(({request}) => {

                // Get the id and tag
                const id = request.body.id;
                const tag = cloneDeep(request.body.tag);

                // Prepare the updated tag
                let updatedTag = null;

                // Find the tag and update it
                this._tags.forEach((item, index, tags) => {

                    if ( item.id === id )
                    {
                        // Update the tag
                        tags[index] = assign({}, tags[index], tag);

                        // Store the updated tag
                        updatedTag = tags[index];
                    }
                });

                // Return the response
                return [200, updatedTag];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Tag - DELETE
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onDelete('api/patents/tag')
            .reply(({request}) => {

                // Get the id
                const id = request.params.get('id');

                // Find the tag and delete it
                this._tags.forEach((item, index) => {

                    if ( item.id === id )
                    {
                        this._tags.splice(index, 1);
                    }
                });

                // Get the patents that have the tag
                const patentsWithTag = this._patents.filter(patent => patent.tags.indexOf(id) > -1);

                // Iterate through them and delete the tag
                patentsWithTag.forEach((patent) => {
                    patent.tags.splice(patent.tags.indexOf(id), 1);
                });

                // Return the response
                return [200, true];
            });
            */
        // -----------------------------------------------------------------------------------------------------
        // @ Avatar - POST
        // -----------------------------------------------------------------------------------------------------
        
        /*
         * Read the given file as mock-api url
         *
         * @param file
         */
        /*const readAsDataURL = (file: File): Promise<any> =>

            // Return a new promise
            new Promise((resolve, reject) => {

                // Create a new reader
                const reader = new FileReader();

                // Resolve the promise on success
                reader.onload = (): void => {
                    resolve(reader.result);
                };

                // Reject the promise on error
                reader.onerror = (e): void => {
                    reject(e);
                };

                // Read the file as the
                reader.readAsDataURL(file);
            })
        ;

        this._fuseMockApiService
            .onPost('api/patents/avatar')
            .reply(({request}) => {

                // Get the id and avatar
                const id = request.body.id;
                const avatar = request.body.avatar;

                // Prepare the updated patent
                let updatedPatent: any = null;

                // In a real world application, this would return the path
                // of the saved image file (from host, S3 bucket, etc.) but,
                // for the sake of the demo, we encode the image to base64
                // and return it as the new path of the uploaded image since
                // the src attribute of the img tag works with both image urls
                // and encoded images.
                return from(readAsDataURL(avatar)).pipe(
                    map((path) => {

                        // Find the patent and update it
                        this._patents.forEach((item, index, patents) => {

                            if ( item.id === id )
                            {
                                // Update the avatar
                                patents[index].avatar = path;

                                // Store the updated patent
                                updatedPatent = patents[index];
                            }
                        });

                        // Return the response
                        return [200, updatedPatent];
                    })
                );
            });*/
    }
}
