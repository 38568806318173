export const environment = {
    production: true,
    apiUrl: 'https://epoapi.now-dns.net:1339',
    strApiUrl: 'https://iptriz-ojcpmpbreq-uc.a.run.app',
    appVersion: require('../../package.json').version,
    firebaseConfig: {
        apiKey: "AIzaSyBCcPoIGlJiR5yjcFX77vSqfYOs8vPnzUY",
        authDomain: "iptriz.firebaseapp.com",
        projectId: "iptriz",
        storageBucket: "iptriz.appspot.com",
        messagingSenderId: "443919616742",
        appId: "1:443919616742:web:0cbd2e7081b5f83245c927",
        measurementId: "G-28GNTQJH77"}
};
