/* eslint-disable */
export const user = [{
    id    : 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
    name  : 'Przemyslaw Przytula',
    email : 'przemyslaw.przytula@crido.pl',
    password: 'TRIZRules',
    avatar: 'assets/images/avatars/default.png',
    status: 'online'
},
{
    id    : 'cfaad35d-07a4-4547-c6c3-e7c3d54fd5df',
    name  : 'Valeriy Prushinskiy',
    email : 'valprushinskiy@gmail.com',
    password: 'TRIZRules',
    avatar: 'assets/images/avatars/default.png',
    status: 'online'
}];
