/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'patents',
        title: 'Search',
        type : 'basic',
        icon : 'heroicons_outline:search',
        link : 'patents'
    },
    {   id   : 'singlePatent',
        title: 'Folder',
        type : 'collapsable',
        icon : 'heroicons_outline:folder',
        children: [
            {
            id   : 'single-patent.data',
            title: 'Patent info',
            type : 'basic',
            link : 'single-patent/data',
            icon : 'heroicons_outline:document-text'
            },
            {
            id   : 'single-patent.claim',
            title: 'Claim',
            type : 'basic',
            link : 'single-patent/claim',
            icon : 'heroicons_outline:clipboard-list'    
        },
                ]
    }
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'search',
        title: 'Search',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/search'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'search',
        title: 'Search',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/search'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'search',
        title: 'Search',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/search'
    }
];
