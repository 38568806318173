import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Edge, Node, ClusterNode } from '@swimlane/ngx-graph';
import { Patent, ClaimGraph, PatentServiceData, AIDeconstruction, AIconversation, MsgChunk} from 'app/modules/admin/apps/single-patent/single-patent.types';
import { BehaviorSubject, filter, map, Observable, of, switchMap, take, tap, throwError } from 'rxjs';

// Import the functions you need from the SDKs you need
import { initializeApp } from "@firebase/app";
import { getAnalytics } from "@firebase/analytics";
import { getFunctions, httpsCallable } from "firebase/functions";
import { AngularFireAction } from '@angular/fire/compat/database';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { result } from 'lodash';
import { analytics } from 'app/mock-api/dashboards/analytics/data';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// Initialize Firebase
const app = initializeApp(environment.firebaseConfig);


@Injectable({
  providedIn: 'root'
})
export class SPService {
  
  //patent: Patent;
  //selectedClaim: number;
  
  
  private _patent: BehaviorSubject<Patent | null> = new BehaviorSubject(null);
  private _selectedClaim: BehaviorSubject<number | null> = new BehaviorSubject(null);
  private _selectedPart: BehaviorSubject<string | null> = new BehaviorSubject(null);
  private _claimGraph: BehaviorSubject<ClaimGraph | null> = new BehaviorSubject(null);
  private _mesgStream: BehaviorSubject<MsgChunk> = new BehaviorSubject(null);
  private _patentServiceData: BehaviorSubject<PatentServiceData | null> = new BehaviorSubject(null);
  private _AIDeconstruction: BehaviorSubject<AIDeconstruction> = new BehaviorSubject(null);
  

  
 
  backendurl = environment.apiUrl;
  strBackendUrl = environment.strApiUrl;
  

  constructor(
    private _httpClient: HttpClient,
    
    
    
    )
  {
  }

  // Http Headers
  httpOptions = {
      headers: new HttpHeaders({
      'Content-Type': 'application/json',
      }),
  };
  
 

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for patent
   */
  get patent$(): Observable<Patent>
  {
      return this._patent.asObservable();
  }

  get selectedClaim$(): Observable<number>
  {
      return this._selectedClaim.asObservable();
  }

  get selectedPart$(): Observable<string>
  {
      return this._selectedPart.asObservable();
  }

  get patentServiceData$(): Observable<PatentServiceData>
  {
      return this._patentServiceData.asObservable();
  }

  get claimGraph$(): Observable<ClaimGraph>
  {
      return this._claimGraph.asObservable();
  }

  get mesgStream$(): Observable<MsgChunk>
  {
      return this._mesgStream.asObservable();
  }

  get AIDeconstruction$(): Observable<AIDeconstruction>
  {
      return this._AIDeconstruction.asObservable();
  }

  updatePatentData(patent: Patent) {
    console.log("SSinglePatent: updating patent and sending data to subscribers.");
    this._patent.next(patent);
    
  }

  updateSelectedPart(selectedPart:any) {
    console.log("SSinglePatent: updating selectedPart and sending data to subscribers.");
    this._selectedPart.next(selectedPart);
    
  }

  updateClaimGraph(claimGraph:any) {
    console.log("SSinglePatent: updating claimGraph and sending data to subscribers.");
    this._claimGraph.next(claimGraph);
    
  }

  getPatentData(pId: string, cId: string): Observable<PatentServiceData>
    {
        //console.log("request summary id " + id);
        const options = pId ?
        { params: new HttpParams()
            .set('patentId', pId)
            } : {
          params: new HttpParams()
          .set('patentId', 0)
            };
        //this.selectedClaim = Number(cId);    
        this._selectedClaim.next(Number(cId));
        //console.log("wybrane Claim ID to: " + cId);
        //console.log("wybrane Patent ID to: " + pId);
        return this._httpClient.get<PatentServiceData>(this.backendurl + '/getclaim',options )
        .pipe(
          tap(data => {
            //this.patent = data.patent;
            //console.log("Serwer zwrócił dane:");
            //console.log(data);
            this._patent.next(data.patent);
            return data;
          }),
          switchMap((data) => {
              if ( !data ) { return throwError('Could not found patent with id of ' + pId + '!'); }
              return of(data);
          })
        )
    }

    getClaimPartData(pId: string, cId: string, tId)//: Observable<PatentServiceData>
    {
        //console.log("request part id " + tId);
        //this._selectedPart.next(tId);
/*
        const options = pId ?
        { params: new HttpParams()
            .set('patentId', pId)
            } : {
          params: new HttpParams()
          .set('patentId', 0)
            };
        //this.selectedClaim = Number(cId);    
        this._selectedClaim.next(Number(cId));
        //console.log("wybrane Claim ID to: " + cId);
        //console.log("wybrane Patent ID to: " + pId);
        return this._httpClient.get<PatentServiceData>(this.backendurl + '/getclaim',options )
        .pipe(
          tap(data => {
            //this.patent = data.patent;
            //console.log("Serwer zwrócił dane:");
            //console.log(data);
            this._patent.next(data.patent);
            return data;
          }),
          switchMap((data) => {
              
              if ( !data )
              {
                  return throwError('Could not found patent with id of ' + pId + '!');
              }

              return of(data);
          })
        )*/
    }

    getAIDeconstruction(part: string, assistant:any, tid: string): any//Observable<AIDeconstruction>
    {
      console.log('SPService: asking AI for tree decomposition.');
      const functions = getFunctions();
      const aiSplitTextClaimBlock2 = httpsCallable(functions, 'aiSplitTextClaimBlock2');
    //const claim = patent.claims[cId].text;
    //const claim = "\"(3) maintaining one or more groups, each of said one or more groups comprising any number of said patents from said at least one first database\; and\"";
      try {
        const fc = aiSplitTextClaimBlock2({
          claim: part,
          modelIn: assistant.id,
          instructions: assistant.instructions
        });
        const aid = assistant.id;
        return {
          fc,
          tid,
          aid
        }
      }
      catch(err) {
        console.error("SSinglePatent: Error aiSplitTextClaimBlock2: " + err);
      }
      
      
    }

    getAIChat(message:string, assistant_id:any, thread_id):any {
      console.log('SPService: adding new message.');
      const functions = getFunctions();
      try {
        const aiChat2 = httpsCallable(functions, 'aiChat2');
        const result = aiChat2({
          message: message,
          thread: thread_id,
          assistant_id: assistant_id
        });
        return result;
      }
      catch (err)
        {
          console.error("SSinglePatent: Error getAIChat: " + err);
        }
      
      
    }

    async getAIChatModel(chat:any) {
    /*  const options =  params: new HttpParams()
      .set('chat', chat)
      .set('type', type);
      */
      

      const options = chat ?
      { params: new HttpParams()
          .set('chat', chat)
          .set('type', chat.type)
          } : {
        params: new HttpParams()
          .set('chat', 0)
          .set('type', chat.type)
          };
      //chat.messages.pop();
      console.log('SPService: chat to be sent...');
      console.log(chat);

      console.log('SPService: adding new messages chat complition to ' + this.strBackendUrl + '/getOaiClaimCircumvention.');
      fetch(this.strBackendUrl + '/getOaiClaimCircumvention', {
        method: "POST",
        headers: { "Content-Type": "application/json", },
        body: JSON.stringify({ 
          chat: chat,
          type: chat.type,
        }),
       }
      )
       .then (async (response) => {
          const textDecoder = new TextDecoder();
          const reader = response.body.getReader();
          
          let msgChunk = {
            chunkText: null,
            chat_id: chat.id,
            meta: "msgStart"
          };
          this._mesgStream.next(msgChunk);

          for await (const chunk of this.readChunks(reader,chat.id)) {
            
            msgChunk = {
              chunkText: textDecoder.decode(chunk),
              chat_id: chat.id,
              meta: "msgChunk"
            };
            this._mesgStream.next(msgChunk);
            
          }
          console.log("Stream is done.");
          msgChunk = {
              chunkText: null,
              chat_id: chat.id,
              meta: "msgStop"
            };
            console.log(msgChunk);
            this._mesgStream.next(msgChunk);
    });
  }

  readChunks(reader,chat_id) {
    
    return {
        async* [Symbol.asyncIterator]() {
            let readResult = await reader.read();
            while (!readResult.done) {
                yield readResult.value;
                readResult = await reader.read();
            }
            yield readResult.value;
        },
    };
  }
    
    aiGetAssistantsList(): any {
      console.log('SPService: getting assistants list.');
      const functions = getFunctions();
      const aiGetAssistantsList = httpsCallable(functions, 'aiGetAssistantsList');
      try {
        return aiGetAssistantsList();
      }
      catch(err) {

      }
      
    } 

  
  aiGetModelsList(): any {
    console.log('SPService: pobieram listę modeli.');
    const functions = getFunctions();
    const aiGetModelsList = httpsCallable(functions, 'aiGetModelsList');
    return aiGetModelsList()
    
  } 

  UpdateGraph(patent: Patent, cId: number): ClaimGraph 
  {
    //console.log(cId);
    //console.log(patent);

    let claim = patent.claim[cId];
    let nodes:Node[] = [];
    let links:Edge[] = [];
    let clusters:ClusterNode[] = [];
    let nodeWidth = '190';
    //console.log("SPS: updating the graph.");
    //console.log("SPS: claim: " + claim);
    //console.log("SPS: claim length: " + claim.length);
    //console.log("SPS: patent: " + patent);
    //console.log("SPS: cId: " + cId);
    //console.log("SPS: claim: " + claim);
      
    for (let i = 0,k = 0; i < claim.length; i++) {
      //console.log(i);
      if (claim[i].type == "text" || claim[i].type == "textA") {
        //console.log("dodaję cluster: " + claim[i].tid);
        clusters.push( {
          id: "c" + claim[i].tid,
          label: claim[i].tid,
          childNodeIds: []
        }

        )
      } 
      if (claim[i].type == "p" || claim[i].type == "pA") {
          //console.log("dodaję node: " + claim[i].tid);
          nodes.push( {
            id: "n" + claim[i].tid,
            label: claim[i].txt,
            data: {
              focus: "fresh",
              status: "fresh",
              chat_id: null,
              backgroundColor: '#e1e5eb',
              width: nodeWidth,
              height: this.calculateHeight(claim[i].txt, Number(nodeWidth), 16)
            }
            
          });
          for (let j = 0; j < clusters.length; j++) {
            //console.log("sprawdzam przynależnosc node " + "n" + claim[i].tid + " z Gid: " + claim[i].Gid + " z grupa " + clusters[j].id);
            if (clusters[j].id == ("c" + claim[i].Gid)) {

              clusters[j].childNodeIds.push("n" + claim[i].tid);
            }
          }
      
          if (claim[i].pid !== null) {
            links.push( {
              id: "l" + k.toString(),
              source: "n" + claim[i].pid,
              target: "n" + claim[i].tid,
              label: k.toString(),
              data: {
                direction: "up"
              }
            })
            k++;
          }
        
        }
    }
    for (let i = (clusters.length-1); i >=0; i--) {
      if (clusters[i].childNodeIds.length < 2) {
        clusters.splice(i,1);
      }
    } 
    
    let newGraph: ClaimGraph = {nodes, links, clusters};
    this.updateClaimGraph(newGraph);
    //console.log(newGraph);
    return newGraph;
}

calculateHeight(text: string, width: number = 200, fontSize: number = 16): number {
  let height = ((text.length / width * 6.25) + 1 ) * (fontSize + 4) + 10;

  return height;
}


  }

