import { environment } from './../../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, filter, map, Observable, of, switchMap, take, tap, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Patent, PatentPage, PatentSummary, PatentSummaryAI, PatentClaims, ClaimGraph } from 'app/modules/admin/apps/patents/patents.types';
import { Graph } from 'msagl-js';

@Injectable({
    providedIn: 'root'
})
export class PatentsService
{
    // Private
    private _patent: BehaviorSubject<Patent | null> = new BehaviorSubject(null);
    private _claimGraph: BehaviorSubject<ClaimGraph | null> = new BehaviorSubject(null);
    private _patents: BehaviorSubject<Patent[] | null> = new BehaviorSubject(null);
    private _patentPage: BehaviorSubject<PatentPage | null> = new BehaviorSubject(null);
    //private _patentSummary: BehaviorSubject<PatentSummary | null> = new BehaviorSubject(null);
    private _patentClaims: BehaviorSubject<PatentClaims | null> = new BehaviorSubject(null);
    backendurl = environment.apiUrl;
    //private patent$ = new BehaviorSubject<any>({});
    //selectedPatent$ = this.patent$.asObservable();

      /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
    }

    // Http Headers
    httpOptions = {
        headers: new HttpHeaders({
        'Content-Type': 'application/json',
        }),
    };
    


    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for patent
     */
    get patent$(): Observable<Patent>
    {
        return this._patent.asObservable();
    }

    get claimGraph$(): Observable<ClaimGraph>
    {
        return this._claimGraph.asObservable();
    }

    /**
     * Getter for patents
     */
    get patents$(): Observable<Patent[]>
    {
        return this._patents.asObservable();
    }
    
    /**
     * Getter for patents
     */
    get patentPage$(): Observable<PatentPage>
    {
        return this._patentPage.asObservable();
    }
/*
    get patentSummary$(): Observable<PatentSummary>
    {
        return this._patentSummary.asObservable();
    }
*/
    get patentClaims$(): Observable<PatentClaims>
    {
        return this._patentClaims.asObservable();
    }
    
        
    /**
     * Search patents with given query
     *
     * @param query
     * @param queryType
     * @param size
     * @param page
     */
    /*
    searchSummary(id: string)
    {
        //console.log("request summary id " + id);
        const options = id ?
        { params: new HttpParams()
            .set('patentId', id)
            } : {
          params: new HttpParams()
          .set('patentId', 0)
            };
            
            // q: why I do not get any response from server?


        return this._httpClient.get<PatentSummary>(this.backendurl + '/sqlsummary',options )
        .pipe(tap((patentSummary) => {
            this._patentSummary.next(patentSummary);
            //console.log("get result summary");
            console.log("summary meta: " + patentSummary.meta);
            //console.log(patentSummary.str);
                })
            );
    }*/
/*
    searchSummaryAI(id: string)
    {
        //console.log("request summary id " + id);
        const options = id ?
        { params: new HttpParams()
            .set('patentId', id)
            } : {
          params: new HttpParams()
          .set('patentId', 0)
            };
            
            // q: why I do not get any response from server?


        return this._httpClient.get<PatentSummaryAI>(this.backendurl + '/getclaims',options )
        .pipe(tap((patentSummaryAI) => {
            this._patentSummaryAI.next(patentSummaryAI);
            //console.log("get result summary");
            console.log("ai summary meta: " + patentSummaryAI.meta);
            console.log(patentSummaryAI.data);
                })
            );
    }*/

    getClaims(id: string)
    {
        //console.log("request summary id " + id);
        const options = id ?
        { params: new HttpParams()
            .set('patentId', id)
            } : {
          params: new HttpParams()
          .set('patentId', 0)
            };
            
            // q: why I do not get any response from server?


        return this._httpClient.get<PatentClaims>(this.backendurl + '/getpatent',options )
        .pipe(
            tap((data) => {
            //this._patent.next(patent);
            //console.log("claims meta: " + data.meta);
            //console.log(data.patent);
            })
            );
    }

    searchPatents(query: string, queryType: string, page: number, size: number): Observable<PatentPage>
    {
        // q: while I run this function onec but I send two requests to server?


        //console.log("request");
        //console.log("request query: " + query);
        //console.log("request queryType: " + queryType);
        //console.log("request page: " + page);
        //console.log("request size: " + size);
        const options = query ?
        { params: new HttpParams()
            .set('type', queryType)
            .set('page', page)
            .set('size', size)
            .set('search', query)
            } : {
          params: new HttpParams()
                .set('page', page)
                .set('size', size)
            };
        return this._httpClient.get<PatentPage>(this.backendurl + '/getpatents', options )
        .pipe(tap((patentPage) => {
            this._patentPage.next(patentPage);
            //console.log("get result");
            //console.log(patentPage.meta);
            //console.log(patentPage.data);
                })
            );
    }
    

    /**
     * Get patent by id
     */
    /*
    splitClaims(claims: string): any[] {
        const searchRegExpSlash = new RegExp('\\\\','g');
        const searchRegExpId = new RegExp(' id="([a-z]|[0-9]|-)+"','g');
        const searchRegExpNum = new RegExp(' num="[0-9]+"','g');

        const searchDepClaims = new RegExp(' [cC]laims? [0-9]+[ ]?[a-z,]{0,3}[ ]?[0-9]*','');
        const searchNumbers = new RegExp('[0-9]+','g');
        const searchSpread = new RegExp('[0-9]+[ ]?[a-z,]{0,3}[ ]?[0-9]*','g');
        const searchConnector = new RegExp('[a-z,]{1,3}','g');

        claims = claims.replaceAll(searchRegExpSlash, '');
        claims = claims.replaceAll(searchRegExpId, '');
        claims = claims.replaceAll(searchRegExpNum, '');
        const breakPoint = RegExp('<claim>', 'g');
        const claimsArray = claims.split(breakPoint);
        claimsArray.shift();
        let claimsArrayObj = [];
        
        for (let i = 0; i < claimsArray.length; i++) {
                        
            let parentClaimsArray = [];
            let newParentClaimsArray = [];
            if(claimsArray[i].search(searchDepClaims) != -1) {
                const l = claimsArray[i].match(searchDepClaims);
                const j = l[0].match(searchSpread);
                let type= '';
                let min = 0;
                let max = 0;
                let len = 0;
                let o   =0;
                let mark = true;
                let claimPointer = 0;
                
                if(j[0].search(searchConnector) != -1) {
                    
                    const connector = j[0].match(searchConnector);
                    switch(connector[0]) {
                        case ',':
                            type = "and";
                            break;
                        case 'and':
                            type = "and";
                            break;
                        case 'or':
                            type = "and";
                            break;
                        case 'to':
                            type = "to";
                            break;
                        default:
                            type = '';
                    }
                }
                parentClaimsArray = j[0].match(searchNumbers);
                //console.log(type + " :type & length: " + parentClaimsArray.length);
                if(type == "to" && parentClaimsArray.length == 2) {
                    min = parentClaimsArray[0];
                    max = parentClaimsArray[1];
                    len = max - min;
                    //console.log("min/max/len: " + min + " " + max + " " + len);
                    for (let k = 0; k <= len;  k++) {
                        parentClaimsArray[k] = String(Number(min) + Number(k));
                    }
                }
                
                //console.log("claim: " + (i+1) + " have basic parents: ");
                //console.log(parentClaimsArray);
                
                let grandParents = [];
                o = 0;
                for (let m = 0; m < parentClaimsArray.length; m++) {
                    claimPointer = Number(parentClaimsArray[m])-1;
                    //console.log("parentClaimsArray[m]: " + parentClaimsArray[m] + " claimPointer: " + claimPointer);
                    for (let n = 0; n < claimsArrayObj[(claimPointer)].parent.length; n++) {
                        //console.log("parentClaimsArray[m]: " + parentClaimsArray[m] + " claimPointer: " + claimPointer + " claimsArrayObj[(claimPointer)].parent[n]: " + claimsArrayObj[(claimPointer)].parent[n]);
                        grandParents[o] = claimsArrayObj[(claimPointer)].parent[n];
                        o++;
                    }
                }
                
                //console.log("grandParents: ");
                //console.log(grandParents);

                o = 0;
                for (let m = 0; m < parentClaimsArray.length; m++) {
                    if(!grandParents.includes(parentClaimsArray[m])) {
                        newParentClaimsArray[o] = parentClaimsArray[m];
                        o++;
                    }
                }
            }
            
            //console.log("claim: " + (i+1) + " have reduced parents: ");
            //console.log(newParentClaimsArray);
              
            claimsArrayObj[i] = {
                id: i+1,
                text: claimsArray[i],
                parent: newParentClaimsArray
            };
            
        }
        //console.log(claimsArrayObj);
        
        return claimsArrayObj;
    }
     */  
    
    mapGraphObject(patent: Patent): ClaimGraph {
        let newGraph: ClaimGraph = {
            links: [
                {
                id: "l1",
                source: "n1",
                target: "n1",
                label: "initial"
                }],
            nodes: [
                {
                id: "n1",
                label: "initial"
                }]
            };
        let k =0;
              
        for (let i = 0; i < patent.claims.length; i++) {
            
        
            newGraph.nodes.splice(i,1,{
                id: "n" + patent.claims[i].id,
                label: patent.claims[i].id
            });
            
            
        
            for (let j = 0; j < patent.claims[i].parent.length; j++) {
                
                newGraph.links.splice(k,1,{
                    id: "l"+ k.toString(),
                    source: "n" + patent.claims[i].parent[j],
                    target: newGraph.nodes[i].id,
                    label: k.toString()

                });
                k++;
            }
        } 
        //console.log(newGraph);
        return newGraph;
    }

    

    UpdateClaimsGraph(patent: Patent) {
        if (patent !== null) {
        this.getClaims(patent.id).subscribe(data => {
            //update patent
            if (data !== null) {
                let patent = data.patent;
                this._patent.next(patent);

                //update claim graph
                let claimGraph = this.mapGraphObject(patent);
                this._claimGraph.next(claimGraph);
                }
            }
        );
        this._patent.next(patent);
        }
    }

    getPatentById(id: string): Observable<Patent>
    {   
        return this._patentPage.pipe(
            take(1),
            map((patentPage) => {
                
                //console.log("getbyid" + patents);
                // Find the patent
                if (patentPage.data != null) {
                    const patent = patentPage.data.find(item => item.id == id) || null;

                //console.log("zmieniam wybrany patent ID: " + id);
                //console.log("nowy wybrany patent:");
                console.log(patent);                
                // Update the patent
                //this._patent.next(patent);

                // Update the Claim Graph
                    this.UpdateClaimsGraph(patent);
                // Return the patent
                    return patent;
                }
                else {
                    return null;
                }
                
            }),
            switchMap((patent) => {
                
                if ( !patent )
                {
                    return throwError('Could not found patent with id of ' + id + '!');
                }

                return of(patent);
            })
        );
    }

    /**
     * Create patent
     
    createPatent(): Observable<Patent>
    {
        return this.patents$.pipe(
            take(1),
            switchMap(patents => this._httpClient.post<Patent>('api/patents/patent', {}).pipe(
                map((newPatent) => {

                    // Update the patents with the new patent
                    this._patents.next([newPatent, ...patents]);

                    // Return the new patent
                    return newPatent;
                })
            ))
        );
    }

    
     * Update patent
     *
     * @param id
     * @param patent
     
    updatePatent(id: string, patent: Patent): Observable<Patent>
    {
        return this.patents$.pipe(
            take(1),
            switchMap(patents => this._httpClient.patch<Patent>('api/patents/patent', {
                id,
                patent
            }).pipe(
                map((updatedPatent) => {

                    // Find the index of the updated patent
                    const index = patents.findIndex(item => item.id === id);

                    // Update the patent
                    patents[index] = updatedPatent;

                    // Update the patents
                    this._patents.next(patents);

                    // Return the updated patent
                    return updatedPatent;
                }),
                switchMap(updatedPatent => this.patent$.pipe(
                    take(1),
                    filter(item => item && item.id === id),
                    tap(() => {

                        // Update the patent if it's selected
                        this._patent.next(updatedPatent);

                        // Return the updated patent
                        return updatedPatent;
                    })
                ))
            ))
        );
    }

    
     * Delete the patent
     *
     * @param id
     
    deletePatent(id: string): Observable<boolean>
    {
        return this.patents$.pipe(
            take(1),
            switchMap(patents => this._httpClient.delete('api/patents/patent', {params: {id}}).pipe(
                map((isDeleted: boolean) => {

                    // Find the index of the deleted patent
                    const index = patents.findIndex(item => item.id === id);

                    // Delete the patent
                    patents.splice(index, 1);

                    // Update the patents
                    this._patents.next(patents);

                    // Return the deleted status
                    return isDeleted;
                })
            ))
        );
    }*/
}
